'use client';

import { Loader } from '~/components/atoms/loader/Loader';
import LayoutWrapper from '~/components/layout/LayoutWrapper';

export default function loading() {
  return (
    <LayoutWrapper>
      <Loader />
    </LayoutWrapper>
  );
}

import type { ReactNode } from 'react';
import { cn } from '~/lib/utils';
import type { PropsWithBase } from '~/types/utility/jsx';
import { Typo } from '../ui/typography';

type LayoutWrapperSize = 'lg' | 'md';

const LayoutWrapper = ({
  children,
  className,
  title,
  actions,
  size = 'md',
}: LayoutTitleProps &
  PropsWithBase & {
    size?: LayoutWrapperSize;
  }) => {
  return (
    <div
      className={cn('m-auto h-full max-w-[1076px] px-4 py-4 ', className, {
        'max-w-[1060px]': size === 'md',
        'max-w-[1280px]': size === 'lg',
      })}
    >
      {(title ?? actions) ? <LayoutTitle title={title} actions={actions} /> : null}

      {children}
    </div>
  );
};

type LayoutTitleProps = {
  title?: string;
  actions?: ReactNode[];
};

const LayoutTitle = ({ title, actions }: LayoutTitleProps) => {
  return (
    <div className="mb-4 flex items-center justify-between sm:mb-8">
      {title ? <Typo variant="h2">{title}</Typo> : null}
      {actions ? <div className="flex w-fit space-x-2">{actions}</div> : null}
    </div>
  );
};

export default LayoutWrapper;
